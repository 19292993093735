import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
const baseURL = process.env.REACT_APP_BASEURL;


// Root component to handle base URL redirection
const Root = () =>{
  if (window.location.pathname === '/') {
    // Redirect to baseURL if the current pathname is '/'
    window.location.replace(baseURL);
    return null;// Return null to avoid rendering anything during the redirection
  }

// Return the main application wrapped in Router with the base URL
return(
  <React.StrictMode>
     <Router basename={process.env.REACT_APP_BASEURL}>
     <Routes>
     <Route path="/*" element={<App />} />
     </Routes>
    </Router>
  </React.StrictMode>
);
};

// Render the Root component into the DOM
root.render(<Root/>);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
