import React, { useState } from 'react';
import './Home.css';
import Button from '@mui/material/Button';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../Images/soulLogo.png';

function Home() {
  // State to manage input fields
  const [formData, setFormData] = useState({
    hospitalName: '',
    hospitalAddress: '',
    contactEmail: '',
    contactNumber: '',
    numberOfUser: '',
    numberOfBed: '',
    contactPerson: '',
    serverType: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      // Dummy post API call
      await axios.post('http://128.199.28.60:8688/soul/contact/save', formData);
      
      // Show success toast
      toast.success('Form submitted successfully!');
      
      // Clear form data after successful submission
      setFormData({
        hospitalName: '',
        hospitalAddress: '',
        contactEmail: '',
        contactNumber: '',
        numberOfUser: '',
        numberOfBed: '',
        contactPerson: '',
        serverType: ''
      });
    } catch (error) {
      // Show error toast
      toast.error('Failed to submit the form. Please try again.');
    }
  };

  // Handle form clearing
  const handleClear = () => {
    setFormData({
      hospitalName: '',
      hospitalAddress: '',
      contactEmail: '',
      contactNumber: '',
      numberOfUser: '',
      numberOfBed: '',
      contactPerson: '',
      serverType: ''
    });
  };

  return (
    <>
      <div className='WholePage'>
        <div>
          <img style={{height:'85px',width:'85px'}} src={logo} alt="SOUL" />
        </div>
        <div className='Header'>
          SaaS SOULCARE HMIS REQUEST
        </div>
        <div className='ContentBox'>

          <div className='RowBox'>
            <div className='Field'>
              <div className='LabelText'>Hospital Name :</div>
              <input 
                type='text' 
                name='hospitalName' 
                className='LableInput' 
                value={formData.hospitalName} 
                onChange={handleChange} 
                placeholder='Enter hospital name'
              />
            </div>
            <div className='Field'>
              <div className='LabelText'>Hospital Address :</div>
              <input 
                type='text' 
                name='hospitalAddress' 
                className='LableInput' 
                value={formData.hospitalAddress} 
                onChange={handleChange} 
                placeholder='Enter hospital address'
              />
            </div>
          </div>

          <div className='RowBox'>
            <div className='Field'>
              <div className='LabelText'>Contact Email :</div>
              <input 
                type='text' 
                name='contactEmail' 
                className='LableInput' 
                value={formData.contactEmail} 
                onChange={handleChange} 
                placeholder='Enter contact email'
              />
            </div>
            <div className='Field'>
              <div className='LabelText'>Contact Number :</div>
              <input 
                type='text' 
                name='contactNumber' 
                className='LableInput' 
                value={formData.contactNumber} 
                onChange={handleChange} 
                placeholder='Enter contact number'
              />
            </div>
          </div>

          <div className='RowBox'>
            <div className='Field'>
              <div className='LabelText'>Number of User :</div>
              <input 
                type='text' 
                name='numberOfUser' 
                className='LableInput' 
                value={formData.numberOfUser} 
                onChange={handleChange} 
                placeholder='Enter number of users'
              />
            </div>
            <div className='Field'>
              <div className='LabelText'>Number of Bed :</div>
              <input 
                type='text' 
                name='numberOfBed' 
                className='LableInput' 
                value={formData.numberOfBed} 
                onChange={handleChange} 
                placeholder='Enter number of beds'
              />
            </div>
          </div>

          <div className='RowBox'>
            <div className='Field'>
              <div className='LabelText'>Contact Person :</div>
              <input 
                type='text' 
                name='contactPerson' 
                className='LableInput' 
                value={formData.contactPerson} 
                onChange={handleChange} 
                placeholder='Enter contact person'
              />
            </div>
            <div className='Field'>
              <div className='LabelText'>Server Type :</div>
              <select 
                name='serverType' 
                className='LableInput' 
                value={formData.serverType} 
                onChange={handleChange}
              >
                <option value=''>Select Server Type</option>
                <option value='Cloud'>Cloud</option>
                <option value='On Premise'>On Premise</option>
              </select>
            </div>
          </div>

        </div>
        <div className='Btn'>
          <Button className='SubmitBtn' variant="outlined" onClick={handleClear}>Clear</Button>
          <Button className='SubmitBtn' variant="contained" onClick={handleSubmit}>Submit</Button>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Home;
